<template>
  <basic-layout title="Call for Papers">
    <text-section sub-title="Submission Guidelines">
      <p>International Computer Symposium (ICS) is one of the most prestigiousinternational ICT symposiums held in Taiwan. Founded in 1973, it isintended to provide a forum for researchers, educators, and professionals to exchange their discoveries and practices, and toexplore future trends and applications in computer technologies. The biennial symposium offers a great opportunity to share researchexperiences and to discuss potential new trends in the ICT industry. ICS 2024 will provide workshops, panels and keynotes tofacilitate discourse on and deepen the understanding of the challengesin computer and communication technologies.
      </p>
    </text-section>
    <text-section sub-title="Workshops">
      <p>All papers must be original and not simultaneously submitted to another journal or conference. The conference
        will include the following workshops:
      </p>
        <p>1. Algorithms, Bioinformatics, and Computation Theory </p>
        <p>2. Cloud Computing and Big Data</p>
        <p>3. Computer Vision and Image Processing</p>
        <p>4. Cryptography and Quantum Computing</p>
        <p>5. Mobile and Wireless Networks</p>
        <p>6. Autonomous Driving and UAV</p>
        <p>7. Human Computer Interaction and AR/VR/MR</p>
        <p>8. Computer Architecture, Embedded Systems, SoC, and VLSI/EDA</p>
        <p>9. Natural Language Processing and Information Retrieval</p>
        <p>10. Cybersecurity Attack, Defense, and Resilience (Cadence)</p>
        <p>11. AI/ML and Application</p>
	<p>12. AI and Smart Healthcare (Special Session)</p>
	<p>13. AI and Precision Medicine (Special Session)</p>
	<p>14. Digital Transformation and AI in Dental Application (Special Session)</p>
    </text-section>
    <text-section sub-title="Submission">
     <p>Submissions should be made through the ICS 2024 submission page, handled by the EasyChair conference management system: <a href="https://easychair.org/conferences/?conf=ics2024">https://easychair.org/conferences/?conf=ics2024</a></p>
    </text-section>
    <text-section sub-title="Important Dates">
      
        <p>Paper submission due date: June 15 ,2024</p>
        <p>Paper notification: August 15, 2024</p>
        <p>Final paper due date: September 15 ,2024</p>
        <p>Conference date: October 24-26, 2024</p>
      
    </text-section>
    <text-section sub-title="Venue">
      <p>
        The conference will be held in National Yang Ming Chiao Tung University, Taipei, Taiwan, on October 24-26, 2024.
      </p>
    </text-section>
    <text-section sub-title="Contact">
      <p>
        All questions about submissions should be emailed to ics2024@nycu.edu.tw.
      </p>
    </text-section>
  </basic-layout>
</template>

<script>
import BasicLayout from '@/layouts/BasicLayout.vue'
// import ComingSoon from '@/components/ComingSoon.vue'
import TextSection from '@/components/TextSection.vue'

export default {
  components: {
    BasicLayout,
    TextSection,
    // ComingSoon,
  }
}
</script>
