<template>
  <div class="coming-soon">
    <h1 class="text-h4 text-secondary my-10">Coming Soon...</h1>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.coming-soon {
  display: grid;
  align-content: center;
  justify-content: center;
}
</style>