<template>
  <basic-layout title="Keynote Speaker">
    <!-- TODO: 請將 <coming-soon> 標籤置換成正式內容 -->
    <coming-soon></coming-soon>
  </basic-layout>
</template>

<script>
import BasicLayout from '@/layouts/BasicLayout.vue'
import ComingSoon from '@/components/ComingSoon.vue'

export default {
  components: {
    BasicLayout,
    ComingSoon,
  }
}
</script>

