<template>
  <basic-layout title="Organization">
    <!-- TODO: 請將 <coming-soon> 標籤置換成正式內容 -->
    <!-- <coming-soon></coming-soon> -->
    <text-section sub-title="Honorary Chairs">
    
         <p>Chi-Hung Lin, National Yang Ming Chiao Tung University, Taiwan</p>
    
    </text-section>

    <text-section sub-title="Conference General Chairs">
    
       <p>Ying-Tien Wu, Ministry of Education, Taiwan</p>
       <p>Jyh-Cheng Chen, National Yang Ming Chiao Tung University, Taiwan</p>
       <p>Chien-Chao Tseng, National Yang Ming Chiao Tung University, Taiwan</p>
    
    </text-section>

    <text-section sub-title="International Board Chairs">
    
       <p>Ashutosh Sabharwal, Rice University, United States.</p>
    
    </text-section>

    <text-section sub-title="Program Chairs">
    
       <p>Li-Hsing Yen,  National Yang Ming Chiao Tung University, Taiwan</p>
       <p>Meng-Hsun Tsai,  National Yang Ming Chiao Tung University, Taiwan</p>
    
    </text-section>

    <text-section sub-title="Panel Chairs">
    
       <p>Chien Chen,  National Yang Ming Chiao Tung University, Taiwan</p>

    </text-section>

    <text-section sub-title="Industry Program Chairs">
    
       <p>Tien-Fu Chen,  National Yang Ming Chiao Tung University, Taiwan</p>
    
    </text-section>

    <text-section sub-title="Publication Chairs">
    
       <p>Lan-Da Van,  National Yang Ming Chiao Tung University, Taiwan</p>
       <p>Wen-Hsiao Peng,  National Yang Ming Chiao Tung University, Taiwan</p>
    
    </text-section>

    <text-section sub-title="Finance Chairs">
    
       <p>Yen-Yu Lin, National Yang Ming Chiao Tung University, Taiwan</p>
    
    </text-section>

    <text-section sub-title="Local Arrangement Chairs">
    
       <p>Chi-Yu Li,  National Yang Ming Chiao Tung University, Taiwan</p>
    
    </text-section>

    <text-section sub-title="Poster Chairs">
    
       <p>Kuan-Wen Chen,  National Yang Ming Chiao Tung University, Taiwan</p>
    
    </text-section>

    <text-section sub-title="Publicity Chairs">
    
       <p>Jiun-Long Huang,  National Yang Ming Chiao Tung University, Taiwan</p>
       <p>Albert Chih-Chieh Yang,  National Yang Ming Chiao Tung University, Taiwan</p>
    
    </text-section>

    <text-section sub-title="Registration Chairs">
    
       <p>Chun-Ying Huang, National Yang Ming Chiao Tung University, Taiwan</p>
    
    </text-section>

    <text-section sub-title="Web Chairs">
    
       <p>Yu-Shuen Wang,  National Yang Ming Chiao Tung University, Taiwan</p>
    
    </text-section>

  </basic-layout>
</template>

<script>
import BasicLayout from '@/layouts/BasicLayout.vue'
//import ComingSoon from '@/components/ComingSoon.vue'
import TextSection from '@/components/TextSection.vue'

export default {
  components: {
    BasicLayout,
    TextSection,
 //   ComingSoon,
  }
}
</script>

